import React, { useEffect, useState, useRef } from 'react';
import Ably from 'ably/promises';
import { InformationCircleIcon } from '@heroicons/react/solid';
import ScreenView from './ScreenView';
import PreRenderPlaceholder from './PreRenderPlaceholder';
import Expire from './Expire';

/*
 * People aren't shown if they aren't active in the last X ms.
 */
const RECENTLY_ACTIVE_THRESHOLD = 5 * 60 * 1000;

/*
 * This is mostly based on the LiveReplayer component and is used on the
 * single person live view. Lots of not DRY work here.
 */
const ActivePeople = (props) => {
  // the ably connection
  const [ably, setAbly] = useState();
  const [siteChannel, setSiteChannel] = useState();

  /*
   * Connect to ably on initial rendering and attach to the site channel.
   * Detach the channel and close the connection when unmounting.
   */
  useEffect(() => {
    setAbly(
      new Ably.Realtime({
        key: props.ablyApiKey,
        clientId: `operator:${props.operator.id}`,
        transports: ['web_socket'],
      }),
    );
  }, []);

  /*
   * Set the site channel when ably connection reaches the 'connected' state for the first time.
   * Close the connection when unmounted.
   */
  useEffect(() => {
    if (!ably) {
      return;
    }
    ably.connection.once('connected', function () {
      console.debug('Ably connected.');
      setSiteChannel(ably.channels.get(props.apiKey));
    });

    return function cleanup() {
      console.debug('Cleaup - closing ably connection.');
      ably.close();
    };
  }, [ably]);

  // An array of session objects {clientId, delay (in seconds to expire)}
  const [activeSessions, setActiveSessions] = useState([]);
  // Ref to the state so callbacks can reference the current value. Otherwise, they
  // only reference the original value.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const activeSessionsRef = useRef();
  activeSessionsRef.current = activeSessions;

  // Sets active people to the list of channel members.
  // Limited to 4 for frontend performance reasons.
  const getChannelMembers = () => {
    if (!siteChannel) {
      return;
    }
    siteChannel.presence.get(function (err, members) {
      if (err) {
        return console.error('Error fetching presence data');
      }
      // filter out operators
      members = members.filter((member) => member.data.userType != 'operator');
      // filter to specific people
      if (props.people_ids.length) {
        members = members.filter((member) => props.people_ids.includes(member.data.personId));
      } else {
        console.debug(`There are ${members.length} total members on the channel. Members=${members}`);
        // filter to those where the document is visible
        members = members.filter((member) => member.data.document_visible !== false);
        console.debug(`There are ${members.length} members with visible screens on the channel. Members=${members}`);
        // filter to those that have a last active value
        members = members.filter((member) => member.data.last_active_at);
        console.debug(`There are ${members.length} members with a non-null last_active_at value. Members=${members}`);
        // filter to those that have been active in the last five minutes
        members = members.filter((member) => member.data.last_active_at >= new Date() - RECENTLY_ACTIVE_THRESHOLD);
        console.debug(`There are ${members.length} members that are recently active. Members=${members}`);
      }
      // Sort by last_active_at
      members = members.sort((a, b) => ((a.data.last_active_at || 0) < (b.data.last_active_at || 0) ? 1 : -1));
      members = members.slice(0, 1);
      setActiveSessions(members.map((member) => ({ ...member.data, clientId: member.clientId })));
    });
  };

  /*
   * Get channel members when the siteChannel is set.
   * When unmounted detach the channel.
   */
  useEffect(() => {
    if (!siteChannel) {
      return;
    }
    getChannelMembers();

    return () => {
      console.debug('Cleaup - detaching from site channel.');
      siteChannel.detach();
      siteChannel.on('detached', function (stateChange) {
        console.debug('Releasing siteChannel');
        // enable garbage collection
        props.ably.channels.release(props.apiKey);
      });
    };
  }, [siteChannel]);

  /*
   * Join the site channel as an operator.
   */
  const joinChannel = () => {
    if (!siteChannel) {
      return;
    }
    siteChannel.presence.enter({ userType: 'operator', userId: props.operator.id }, function (err) {
      if (err) {
        return console.error(err);
      }
      console.debug(
        '[Glass.io] Successfully joined site channel as an operator. channel=',
        siteChannel.name,
        'operator id=',
        props.operator.id,
      );
    });
  };
  useEffect(joinChannel, [siteChannel]);

  const handleSessionExpire = (clientId) => {
    console.debug('Handling expire. clientId=', clientId);
    setActiveSessions((previousSessions) => previousSessions.filter((session) => session.clientId !== clientId));
  };

  const handleInitReplayer = (clientId) => {
    console.debug('handleInitReplayer - clientId=', clientId);
  };

  const handleLiveReplayerComponentCleanup = (clientId) => {};

  const renderedSessionsCount = () => {
    return document.getElementsByClassName('gridContainer').length;
  };

  console.debug('Rendering LiveReplayer for activeSessions=', activeSessions);

  if (activeSessions.length > 0) {
    return (
      <div>
        {activeSessions.map((session) => {
          return (
            <ScreenView
              key={session.clientId}
              channelName={session.clientId}
              sessionId={session.sessionId}
              personId={session.personId}
              ably={ably}
              callRoomId={props.callRoomId}
              siteId={props.siteId}
              siteName={props.siteName}
              dailyUrl={props.dailyUrl}
              disableReplay={props.disableReplay}
              disableFetch={props.disableFetch}
              operator={props.operator}
              hasEvents={props.hasEvents}
              onInitReplayer={handleInitReplayer}
              onCleanup={handleLiveReplayerComponentCleanup}
              calendarUrl={props.calendarUrl}
              message={props.message}
              buttonColor={props.buttonColor}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Waiting for an active person...</h3>
      </div>
    );
  }
};

ActivePeople.defaultProps = {
  people_ids: [],
  expireDelay: 3 * 60,
};

export default ActivePeople;
