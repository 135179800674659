import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

// Don't want suffix
// https://github.com/nmn/react-timeago/issues/72
const formatterFn = buildFormatter({
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: 'from now',
  seconds: '< 1 min',
  minute: '1 min',
  minutes: '%d min',
  hour: '1 hr',
  hours: '%d hrs',
  day: '1 day',
  days: '%d days',
  month: '1 month',
  months: '%d months',
  year: '1 year',
  years: '%d years',
  wordSeparator: ' ',
  numbers: [],
});

export default formatterFn;
