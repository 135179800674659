import Apple from '../../assets/images/device_icons/os/apple.svg';
import Windows from '../../assets/images/device_icons/os/windows.svg';
import Linux from '../../assets/images/device_icons/os/linux.svg';
import Android from '../../assets/images/device_icons/os/android.svg';

export default function OSIcon({ name }: { name: string }) {
  const iconSrc = (name: string) => {
    let res = null;
    switch (name) {
      case 'apple':
        res = Apple;
        break;
      case 'windows':
        res = Windows;
        break;
      case 'linux':
        res = Linux;
        break;
      case 'Android':
        res = Android;
        break;
    }
    return res;
  };

  const icon = iconSrc(name);

  if (icon) {
    return (
      <>
        <img src={icon} className="device-icon w-4" />
      </>
    );
  } else {
    return null;
  }
}
