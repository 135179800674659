import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

export default function UTMIcon(props) {
  const tooltipContent = () => {
    return `
      <pre>
      <code>
        ${JSON.stringify(props.params, undefined, 2)}
      </code>
      </pre>
    `;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (Object.keys(props.params).length > 0) {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="device-icon ml-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          data-html={true}
          data-tip={tooltipContent()}
          data-for="utm"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
          />
        </svg>
        <ReactTooltip effect="solid" id="utm" />
      </>
    );
  } else {
    return null;
  }
}
