/* Logic for flashing the page title */
export default class FlashTitle {
  constructor(newTitle) {
    this.original = document.title;
    this.newTitle = newTitle;
  }

  set newTitle(t) {
    this._newTitle = t;
  }

  get newTitle() {
    return this._newTitle;
  }

  start() {
    if (this.intervalId) {
      console.warn('FlashTitle already running. Not starting again.');
      return;
    }
    this.intervalId = setInterval(() => {
      this.toggle();
    }, 1500);
  }

  toggle() {
    document.title == this.original ? (document.title = this.newTitle) : (document.title = this.original);
  }

  stop() {
    if (!this.intervalId) return;
    clearInterval(this.intervalId);
    this.intervalId = null;
    document.title = this.original;
  }

  isActive() {
    return !!this.intervalId;
  }
}
