// ONLY USED WHEN SESSIONSTACK ISN'T USED. See session-stack-sessions
// for similar logic when sessionstack is configured.

// Interval in ms to poll for people active / inactive status
const POLL_INTERVAL_MS = 60 * 1000;

/*
 * Checks the active/inactive status of people. Grabs the status of people
 * from elements that have a `data-person-id` attribute.
 *
 * Dispatches events for each person id after polling (one if active and another if inactive)
 */
export default class MonitorActivePeople extends HTMLElement {
  constructor() {
    super();
    console.debug('<monitor-active-people> initialized');
  }

  /*
   * Finds all person ids on the page to query for active / inactive.
   */
  personIds() {
    const unique = new Set(
      Array.from(this.personElements()).map((ele) => {
        return ele.getAttribute('data-person-id');
      }),
    );
    return [...unique];
  }

  personElements() {
    return document.querySelectorAll('[data-person-id]');
  }

  connectedCallback() {
    this.startPoll();
  }

  get url() {
    return this.getAttribute('url');
  }

  processResponse(activeData) {
    this.personIds().forEach((personId) => {
      const visit = activeData[personId];
      if (visit) {
        this.dispatchEvent(new CustomEvent('person:status:active', { bubbles: true, composed: true, detail: visit }));
      } else {
        this.dispatchEvent(
          new CustomEvent('person:status:inactive', { bubbles: true, composed: true, detail: { person_id: personId } }),
        );
      }
    });
  }

  startPoll() {
    // Always poll, even if no people as we update live session data for operators
    // via this.
    fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: this.personIds() }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.debug('<monitor-active-people> poll response=', data);
        this.processResponse(data);
        setTimeout(() => {
          this.startPoll();
        }, POLL_INTERVAL_MS);
      })
      .catch((err) => {
        console.error('<monitor-active-people> Stopping poll. poll error=', err);
        // give a bit longer to the next poll after an error
        setTimeout(() => {
          this.startPoll();
        }, POLL_INTERVAL_MS * 3);
      });
  }
}

export const defineCustomElement = () => customElements.define('monitor-active-people', MonitorActivePeople);
