import TabItem from './tab-item';

/**
 * Renders tab in the live view nav tray, adjusting their display to fit the
 * available space.
 */
export default class TabTray extends HTMLElement {
  constructor() {
    super();
    console.debug('<tab-tray> initialized');
    this.initialWidth = null;
  }

  get tabItems() {
    return this.querySelectorAll('tab-item');
  }

  get current() {
    return this.querySelector("tab-item[current = 'true']");
  }

  findTab(person) {
    return Array.from(this.tabItems).find((tab) => tab.person.id == person.id);
  }

  add(person) {
    const newTab = document.createElement('tab-item');
    newTab.person = person;
    this.appendChild(newTab);
    this.sizeTabItems();
  }

  connectedCallback() {
    // should only be 1 tab tray ... set to window for global access.
    window.tabTray = this;
    window.addEventListener('load', (event) => {
      this.sizeTabItems();
    });
    window.addEventListener('resize:done', () => {
      this.sizeTabItems();
    });
  }

  sizeTabItems() {
    this.classList.add('invisible');
    this.unminify();
    Array.from(this.children).forEach((tabItem, _i) => {
      if (!this.isTooWide()) return;
      tabItem.classList.add('mini');
    });
    this.classList.remove('invisible');
  }

  unminify() {
    Array.from(this.children).forEach((tabItem, _i) => {
      tabItem.classList.remove('mini');
    });
  }

  /**
   * Returns `true` if the tab tray width exceeds the container width.
   */
  isTooWide() {
    return this.availableWidth() < 0;
  }

  /**
   * Returns the diference in width between the tab tray and the page container.
   * A value less than zero means the tab tray is wider than than the container.
   */
  availableWidth() {
    return this.container.offsetWidth - this.toggleButton.offsetWidth - this.offsetWidth;
  }

  // The other element displayed alongside the tabs
  get toggleButton() {
    return this.parentElement.nextElementSibling;
  }

  get container() {
    // parent of nav
    return this.parentElement.parentElement;
  }

  hasUnseenMessages() {
    return Array.from(this.tabItems).some((tab) => tab.person.has_unseen_chat_messages);
  }
}

export const defineCustomElement = () => customElements.define('tab-tray', TabTray);
