import React, { useEffect, useState, useRef } from 'react';
import Ably from 'ably/promises';
import { InformationCircleIcon } from '@heroicons/react/solid';
import LiveReplayerComponent from './LiveReplayerComponent';
import PreRenderPlaceholder from './PreRenderPlaceholder';
import Expire from './Expire';

/*
 * People aren't shown if they aren't active in the last X ms.
 */
const RECENTLY_ACTIVE_THRESHOLD = 5 * 60 * 1000;

/*
 * Renders a grid of LiveReplayerComponent objects, one for each active person.
 */
const LiveReplayer = (props) => {
  // the ably connection
  const [ably, setAbly] = useState();
  const [siteChannel, setSiteChannel] = useState();

  /*
   * Connect to ably on initial rendering and attach to the site channel.
   * Detach the channel and close the connection when unmounting.
   */
  useEffect(() => {
    setAbly(
      new Ably.Realtime({
        key: props.ablyApiKey,
        clientId: `operator:${props.operator.id}`,
        transports: ['web_socket'],
      }),
    );
  }, []);

  /*
   * Set the site channel when ably connection reaches the 'connected' state for the first time.
   * Close the connection when unmounted.
   */
  useEffect(() => {
    if (!ably) {
      return;
    }
    ably.connection.once('connected', function () {
      console.debug('Ably connected.');
      setSiteChannel(ably.channels.get(props.apiKey));
    });

    return function cleanup() {
      console.debug('Cleaup - closing ably connection.');
      ably.close();
    };
  }, [ably]);

  // An array of session objects {clientId, delay (in seconds to expire)}
  const [activeSessions, setActiveSessions] = useState([]);
  // Ref to the state so callbacks can reference the current value. Otherwise, they
  // only reference the original value.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const activeSessionsRef = useRef();
  activeSessionsRef.current = activeSessions;

  // Update the page title to include the count of rendered active sessions.
  const updatePageTitle = (count) => {
    const base = 'Glass';
    const title = `${base} (${count})`;
    document.title = title;
  };

  // Sets active people to the list of channel members.
  // Limited to 4 for frontend performance reasons.
  const getChannelMembers = () => {
    if (!siteChannel) {
      return;
    }
    siteChannel.presence.get(function (err, members) {
      if (err) {
        return console.error('Error fetching presence data');
      }
      // filter out operators
      members = members.filter((member) => member.data.userType != 'operator');
      // filter to specific people
      if (props.people_ids.length) {
        members = members.filter((member) => props.people_ids.includes(member.data.personId));
      } else {
        console.debug(`There are ${members.length} total members on the channel. Members=${members}`);
        // filter to those where the document is visible
        members = members.filter((member) => member.data.document_visible !== false);
        console.debug(`There are ${members.length} members with visible screens on the channel. Members=${members}`);
        // filter to those that have a last active value
        members = members.filter((member) => member.data.last_active_at);
        console.debug(`There are ${members.length} members with a non-null last_active_at value. Members=${members}`);
        // filter to those that have been active in the last five minutes
        members = members.filter((member) => member.data.last_active_at >= new Date() - RECENTLY_ACTIVE_THRESHOLD);
        console.debug(`There are ${members.length} members that are recently active. Members=${members}`);
      }
      // Sort by last_active_at, reverse, then grab first 4. Want most recently active.
      members = members.sort((a, b) => ((a.data.last_active_at || 0) < (b.data.last_active_at || 0) ? 1 : -1));
      // limit for performance reasons - reverse back to show most recently active last as that is more consistent
      // with showing new screens at the bottom of the live view grid vs. adding to the front.
      members = members.slice(0, 4).reverse();
      setActiveSessions(members.map((member) => ({ ...member.data, clientId: member.clientId })));
    });
  };

  /*
   * Get channel members when the siteChannel is set.
   * When unmounted detach the channel.
   */
  useEffect(() => {
    if (!siteChannel) {
      return;
    }
    getChannelMembers();

    return () => {
      console.debug('Cleaup - detaching from site channel.');
      siteChannel.detach();
      siteChannel.on('detached', function (stateChange) {
        console.debug('Releasing siteChannel');
        // enable garbage collection
        props.ably.channels.release(props.apiKey);
      });
    };
  }, [siteChannel]);

  /*
   * Subscribe to the site channel presence when the site channel changes.
   * Should only be invoked once as the site channel is set once.
   */
  useEffect(() => {
    const subscribe = () => {
      if (!siteChannel) {
        console.debug('siteChannel not set. Unable to get subscribe to presence.');
        return;
      }
      console.debug('subscribing to site channel presence.');
      // Subscribe
      siteChannel.presence.subscribe(function (presenceMsg) {
        const { action, clientId, data } = presenceMsg;
        console.debug('received message=', presenceMsg);
        // Also includes `update` as this message is generated when a connection is recovered.
        // https://ably.com/docs/realtime/connection#connection-state-recovery
        // data.type:
        // * 'person_entered': sent when entering or re-entering
        // * 'person_updated': sent when updating presence info
        if (
          ['enter', 'update'].includes(action) &&
          (data.type === 'person_entered' || data.type === 'person_updated')
        ) {
          // null-op if the person isn't recently active
          if (!data.last_active_at) {
            return;
          }
          if (data.last_active_at < new Date() - RECENTLY_ACTIVE_THRESHOLD) {
            return;
          }

          if (clientId && !activeSessionsRef.current.includes(clientId)) {
            // filter to specific people
            if (props.people_ids.length && !props.people_ids.includes(data.personId)) {
              // do nothing ... person not in the restricted list.
              console.debug(
                'personId=',
                data.personId,
                ' is not included in the array of filtered people_id. Not rendering.',
              );
            } else {
              if (action == 'enter') {
                console.debug(
                  'A person has entered the channel. clientId=',
                  clientId,
                  'user_info=',
                  data.user_info,
                  'document_visible=',
                  data.document_visible,
                );
              } else if (action == 'update') {
                console.debug(
                  'A person has updated presence info. clientId=',
                  clientId,
                  'user_info=',
                  data.user_info,
                  'document_visible=',
                  data.document_visible,
                );
              }

              // Ensure activepeople is unique.
              // Unique objects:
              // https://yagisanatode.com/2021/07/03/get-a-unique-list-of-objects-in-an-array-of-object-in-javascript/
              setActiveSessions((previousSessions) => [
                ...new Map([...previousSessions, { ...data, clientId }].map((item) => [item.clientId, item])).values(),
              ]);
            }
          } else if (clientId) {
            console.debug(
              'Person is already in the channel. clientId=',
              clientId,
              'user_info=',
              data.user_info,
              'action=',
              action,
            );
          }
        }

        if (action === 'leave') {
          console.debug('A person has left the channel. clientId=', clientId, 'user_info=', data.user_info);
          // disabling audio for now - annoying on more active sites
          // const audio = new Audio('/exit-aim.mp3');
          // audio.play();

          // Rather that immediately removing a session from the live view, set a timeout (in seconds)
          // and wait a bit in case they come back. This reduces distracting churn.
          // https://stackoverflow.com/questions/35206125/how-can-i-find-and-update-values-in-an-array-of-objects
          // const updatedData = originalData.map(x => (x.id === id ? { ...x, updatedField: 1 } : x));
          setActiveSessions((previousSessions) =>
            previousSessions.map((session) =>
              session.clientId === clientId ? { ...session, delay: props.expireDelay } : session,
            ),
          );
        }
      });
    };

    subscribe();
  }, [siteChannel]);

  /*
   * Join the site channel as an operator.
   */
  const joinChannel = () => {
    if (!siteChannel) {
      return;
    }
    siteChannel.presence.enter({ userType: 'operator', userId: props.operator.id }, function (err) {
      if (err) {
        return console.error(err);
      }
      console.debug(
        '[Glass.io] Successfully joined site channel as an operator. channel=',
        siteChannel.name,
        'operator id=',
        props.operator.id,
      );
    });
  };
  useEffect(joinChannel, [siteChannel]);

  console.debug('Rendering LiveReplayer for activeSessions=', activeSessions);

  const handleSessionExpire = (clientId) => {
    console.debug('Handling expire. clientId=', clientId);
    setActiveSessions((previousSessions) => previousSessions.filter((session) => session.clientId !== clientId));
  };

  const handleInitReplayer = (clientId) => {
    console.debug('handleInitReplayer - clientId=', clientId);
    updatePageTitle(renderedSessionsCount());
  };

  const handleLiveReplayerComponentCleanup = (clientId) => {
    updatePageTitle(renderedSessionsCount());
  };

  const handleComponentClick = (personId) => {
    window.location = `/sites/${props.siteId}/live_view/${personId}/person`;
  };

  const renderedSessionsCount = () => {
    return document.getElementsByClassName('gridContainer').length;
  };

  if (activeSessions.length > 0) {
    return (
      <div className="LiveReplayer grid grid-cols-1 gap-4 md:grid-cols-2">
        <PreRenderPlaceholder activeSessions={activeSessions} renderedSessionsCount={renderedSessionsCount()} />
        {activeSessions.map((session) => {
          return (
            <Expire key={session.clientId} id={session.clientId} delay={session.delay} onExpire={handleSessionExpire}>
              <LiveReplayerComponent
                key={session.clientId}
                channelName={session.clientId}
                sessionId={session.sessionId}
                personId={session.personId}
                ably={ably}
                callRoomId={props.callRoomId}
                siteId={props.siteId}
                siteName={props.siteName}
                dailyUrl={props.dailyUrl}
                disableReplay={props.disableReplay}
                disableFetch={props.disableFetch}
                operator={props.operator}
                hasEvents={props.hasEvents}
                onInitReplayer={handleInitReplayer}
                onCleanup={handleLiveReplayerComponentCleanup}
                calendarUrl={props.calendarUrl}
                message={props.message}
                buttonColor={props.buttonColor}
                onClick={handleComponentClick}
              />
            </Expire>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Waiting for an active person...</h3>
      </div>
    );
  }
};

LiveReplayer.defaultProps = {
  people_ids: [],
  expireDelay: 3 * 60,
};

export default LiveReplayer;
