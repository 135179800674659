import { type OperatorChatMacroT } from './types';

export const operatorChatMacros: OperatorChatMacroT[] = [
  {
    name: 'Schedule a meeting',
    requires: ['calendar_url'],
    value: (operator: GlassUserInfo) =>
      `Would you like to <a href="${operator.calendar_url}" target="_blank">schedule a meeting</a>?`,
  },
];
