import React, { useEffect } from 'react';
import rrwebPlayer from 'rrweb-player';

const StaticReplayer = (props) => {
  useEffect(() => {
    // TODO: https://github.com/rrweb-io/rrweb/blob/master/docs/recipes/pagination.md
    console.debug('Loading events=', props.events.length);
    const replayer = new rrwebPlayer({
      target: document.querySelector('.staticReplayer'),
      props: {
        events: props.events,
        autoPlay: false,
      },
    });
    replayer.addEventListener('event-cast', (event) => {
      if (
        event.type == 3 &&
        event.data.source == 0 &&
        !event.data.texts.length &&
        !event.data.adds.length &&
        !event.data.removes.length &&
        event.data.attributes.length
      ) {
        // type=incrementalSnapshotEvent, source=mutationData, and ONLY modifying the attributes.
        // logging for debugging high cpu usage on live view.
        console.log('Incremental snapshot event ONLY modifying attribute=', event);
      }
    });
  }, []); // componentDidMount

  return <div className="staticReplayer flex items-center justify-center"></div>;
};

export default StaticReplayer;
