import React, { useEffect, useRef } from 'react';
import DailyIframe from '@daily-co/daily-js';

const Call = (props) => {
  // Daily IFrame Object.
  // A ref so callbacks can refer to the mutable callFrame object.
  // See https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const callFrameRef = useRef();

  const callAttemptRef = useRef();

  const createCallAttemptPath = `/sites/${props.siteId}/call_attempts`;

  const openModalWindow = () => {
    props.sessionChannel.publish('modal', {
      action: 'open',
      roomId: props.callRoomId,
      operatorName: props.operatorName,
      siteName: props.siteName,
      avatarUrl: props.avatarUrl,
      calendarUrl: props.calendarUrl,
      message: props.message,
      buttonColor: props.buttonColor,
    });

    // open a video call UI for an operator in Glass
    startVideoCall(props.callRoomId);
  };

  const startVideoCall = (callRoomId) => {
    // https://docs.daily.co/reference/daily-js/daily-iframe-class/properties
    const newCallFrame = DailyIframe.createFrame({
      iframeStyle: {
        position: 'fixed',
        width: '375px',
        height: '450px',
        right: '1em',
        bottom: '1em',
      },
      showLeaveButton: true,
      showFullscreenButton: true,
    });

    /*
     * Destroys the callframe iframe.
     */
    const handleLeftMeeting = (msg) => {
      console.debug('left meeting!', 'msg=', msg);
      // null if callFrameRef was destroyed by the prompt timer.
      if (callFrameRef.current) {
        trackCallAttemptUpdate({ left_at: new Date() });
        callFrameRef.current.destroy();
        callFrameRef.current = null;
        callAttemptRef.current = null;
      }
    };

    newCallFrame.on('left-meeting', handleLeftMeeting);
    newCallFrame.on('participant-joined', handleParticipantJoined);
    newCallFrame.on('participant-left', handleParticipantLeft);
    newCallFrame.join({ url: `${props.dailyUrl}/${props.callRoomId}` });
    callFrameRef.current = newCallFrame;

    trackCallAttempt();
  };

  const handleParticipantJoined = (event) => {
    console.log('Call participant joined=', event);
    trackCallAttemptUpdate({ joined_at: new Date() });
  };

  const handleParticipantLeft = (event) => {
    console.log('Call participant left=', event);
    trackCallAttemptUpdate({ left_at: new Date() });
  };

  const trackCallAttempt = () => {
    const payload = {
      session_id: props.sessionId,
    };
    const response = fetch(createCallAttemptPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ call_attempt: payload }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.debug('trackCallAttempt Success:', data);
        callAttemptRef.current = data;
      })
      .catch((error) => {
        console.debug('trackCallAttempt Error:', error);
      });
  };

  const updateCallAttemptPath = () => {
    if (callAttemptRef.current == null) {
      throw 'callAttemptRef is null. Unable to generate update URL.';
    }
    return `${createCallAttemptPath}/${callAttemptRef.current.id}`;
  };

  const trackCallAttemptUpdate = (attributes) => {
    const response = fetch(updateCallAttemptPath(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ call_attempt: attributes }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.debug('trackCallAttemptUpdate Success:', data);
        callAttemptRef.current = data;
      })
      .catch((error) => {
        console.debug('trackCallAttemptUpdate Error:', error);
      });
  };

  // Messages posted when the prompt is auto-dismissed after the timer expires.
  const subscribeToMessages = () => {
    props.sessionChannel.subscribe('prompt-closed-by-timer', function (msg) {
      console.debug('Received message=', 'prompt-closed-by-timer', msg, 'Destroying callframe.');
      // Calling leave() throws an error. Probably because the operator may not have joined yet.
      // Seen cases of duplicate connections, which prompts duplicate messages.
      // Check if callFrameRef exists first.
      if (callFrameRef.current) {
        trackCallAttemptUpdate({ auto_dismissed_at: new Date() });
        callFrameRef.current.destroy();
        callFrameRef.current = null;
        callAttemptRef.current = null;
      }
    });

    props.sessionChannel.subscribe('prompt-mouseenter', function (msg) {
      console.debug('prompt-mouseenter', msg);
      trackCallAttemptUpdate({ seen_at: new Date() });
    });

    props.sessionChannel.subscribe('prompt-snooze', function (msg) {
      console.debug('prompt-snooze', msg);
      trackCallAttemptUpdate({ snoozed_at: new Date() });
    });
  };

  // Runs when initially loaded.
  useEffect(() => {
    subscribeToMessages();
    // TODO - add cleanup and unsub?
  }, []);

  return (
    <button
      className="inline-flex items-center rounded bg-yellow-400 py-2 px-4 hover:bg-yellow-300"
      onClick={openModalWindow}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
        />
      </svg>
      <span className="ml-2">Call</span>
    </button>
  );
};

export default Call;
