import React, { useCallback, useMemo, useState } from 'react';
import { render } from 'react-dom';
import { operatorChatMacros } from './constants';
import { type OperatorChatMacroT } from './types';
import { macroAvailable } from './utils';
import bolt from '../../../assets/images/icons/bolt-light.svg';
import { ChatCommandButton } from '../ChatCommandButton';

export const OperatorChatMacros: React.FC<{ node: HTMLElement; operator: GlassUserInfo }> = ({ node, operator }) => {
  const [macroListOpen, setMacroListOpen] = useState(false);

  const toggleMacroList = () => {
    setMacroListOpen(!macroListOpen);
  };

  const selectMacro = useCallback(
    (macro: OperatorChatMacroT) => {
      setMacroListOpen(false);

      const messageBox = node.shadowRoot?.querySelector<HTMLDivElement>('.message-box');

      if (messageBox == null) {
        return;
      }

      messageBox.innerHTML += macro.value(operator);
      messageBox.focus();
    },
    [operator],
  );

  const macroList = useMemo(() => {
    const availableMacros = operatorChatMacros
      .map(
        (macro) =>
          macroAvailable(macro, operator) && (
            <div
              key={macro.name}
              onClick={() => {
                selectMacro(macro);
              }}
              className="macro"
            >
              {macro.name}
            </div>
          ),
      )
      .filter((element) => element);

    return availableMacros.length > 0 ? availableMacros : <div>No macros available</div>;
  }, [operatorChatMacros, operator]);

  return (
    <div className="macro-toggle-container">
      {macroListOpen && <div className="macro-list">{macroList}</div>}
      <div className="macro-toggle" onClick={toggleMacroList}>
        <ChatCommandButton src={bolt} />
      </div>
    </div>
  );
};

export const registerOperatorChatMacros = () => {
  window.addEventListener('glass-chat:render', ({ detail: { node, operator } }) => {
    const commandsContainer = node.shadowRoot?.querySelector('.chat-commands-container');
    const container = commandsContainer?.insertAdjacentElement('afterbegin', document.createElement('div'));

    if (container != null) {
      render(<OperatorChatMacros node={node} operator={operator} />, container);
    }
  });
};
