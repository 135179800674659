const POLL_INTERVAL_MS = 10 * 1000;

/*
 * Checks for current people in the segment.
 */
export default class MonitorSegmentCurrentPeople extends HTMLElement {
  constructor() {
    super();
    console.debug('<monitor-segment-current-people> initialized');
  }

  connectedCallback() {
    this.startPoll();
  }

  get url() {
    return this.getAttribute('url');
  }

  startPoll() {
    fetch(this.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.debug('<monitor-segment-current-people> poll response=', data);
        this.dispatchEvent(new CustomEvent('segment:people:updated', { bubbles: true, composed: true, detail: data }));
        setTimeout(() => {
          this.startPoll();
        }, POLL_INTERVAL_MS);
      })
      .catch((err) => {
        console.error('<monitor-segment-current-people> Stopping poll. poll error=', err);
        // give a bit longer to the next poll after an error
        setTimeout(() => {
          this.startPoll();
        }, POLL_INTERVAL_MS);
      });
  }
}

export const defineCustomElement = () =>
  customElements.define('monitor-segment-current-people', MonitorSegmentCurrentPeople);
