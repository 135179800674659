import React from 'react';

export default function IndiciaPanel(props) {
  return (
    <div
      className={`${
        props.activePanel != 'indicia' ? 'hidden' : ''
      } overflow-hidden overflow-y-auto border-t border-gray-200`}
    >
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Location</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Earth</dd>
        </div>
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">First Seen</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">ago</dd>
        </div>
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Device</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Desktop • Apple • Firefox</dd>
        </div>
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Visits</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3</dd>
        </div>
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Total time on site</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3:02:03</dd>
        </div>
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Referrer</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            "https://www.learnenough.com/course/ruby_on_rails_tutorial_7th_edition/password_reset/password_reset_emails/password_reset_mailer
          </dd>
        </div>
        <div className="py-1  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Campaign Info</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <span className="ml-2 w-0 flex-1 truncate"> quora </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    utm_source{' '}
                  </a>
                </div>
              </li>
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <span className="ml-2 w-0 flex-1 truncate">
                    {' '}
                    What%20are%20the%20best%20resources%20for%20learning%20Mandarin%20Chinese%3F{' '}
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    utm_medium{' '}
                  </a>
                </div>
              </li>
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <span className="ml-2 w-0 flex-1 truncate"> </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    utm_campaign{' '}
                  </a>
                </div>
              </li>
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <span className="ml-2 w-0 flex-1 truncate"> </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    utm_term{' '}
                  </a>
                </div>
              </li>
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <span className="ml-2 w-0 flex-1 truncate"> </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    utm_content{' '}
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
      <div dangerouslySetInnerHTML={{ __html: props.indicia.user_data_html }}></div>
    </div>
  );
}
