import React, { useEffect } from 'react';
import OSIcon from '../OSIcon';
import BrowserIcon from '../BrowserIcon';
import UTMIcon from '../UTMIcon';
import ReactTooltip from 'react-tooltip';
import DeviceTypeIcon from '../DeviceTypeIcon';

export default function SessionStackScreenHeader(props) {
  const personUrlPath = () => {
    return `/sites/${props.siteId}/live_view/${props.indicia.attributes.id}/person`;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const avatarImage = () => {
    return (
      <person-active-status data-person-id={props.indicia.attributes.id} class="mr-2">
        <person-icon type={props.indicia.attributes.type} avatar_url={props.indicia.avatar_url} />
      </person-active-status>
    );
  };

  if (!props.indicia.attributes.id) {
    return null;
  }

  return (
    <div className="indicia flex flex-wrap items-center py-5 text-sm sm:flex-nowrap sm:px-6">
      <div
        className="flex items-center"
        data-tip="Click for a detailed live view of this person."
        data-for={`person_details_${props.sessionId}`}
      >
        {avatarImage()}
        <a href={personUrlPath()} className="text-blue-600 underline">
          {props.indicia.attributes.label}
        </a>
      </div>
      <ReactTooltip effect="solid" id={`person_details_${props.sessionId}`} />
      <div className="ml-4 justify-self-end text-xs text-gray-500">{props.indicia.attributes.location_to_s}</div>
      <span
        className="ml-auto flex rounded bg-gray-100 px-3 py-1 text-gray-500 shadow-sm"
        data-tip={props.indicia.attributes.user_agent}
        data-for={`user_agent_${props.sessionId}`}
      >
        <OSIcon name={props.indicia.device_icons.os} />
        <BrowserIcon name={props.indicia.device_icons.browser} />
        <DeviceTypeIcon name={props.indicia.device_icons.type} />
      </span>
      <ReactTooltip effect="solid" id={`user_agent_${props.sessionId}`} />
      <UTMIcon params={props.indicia.utm_params} />
    </div>
  );
}
