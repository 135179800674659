import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DailyIframe, { type DailyCall, type DailyMeetingState } from '@daily-co/daily-js';
import pipIcon from '../../../assets/images/icons/video-arrow-up-right-light.svg';

const LiveStream: React.FC<{ operator: GlassUserInfo }> = (props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [callObject, setCallObject] = useState<null | DailyCall>(null);
  const [meetingState, setMeetingState] = useState<null | DailyMeetingState>(null);
  const [videoTrack, setVideoTrack] = useState<null | MediaStreamTrack>(null);

  const join = useCallback(async () => {
    if (callObject) {
      const participants = await callObject.join({
        url: props.operator.live_stream_room_url,
        userName: props.operator.label,
        userData: props.operator,
      });

      if (participants?.local.videoTrack) {
        setVideoTrack(participants.local.videoTrack);
      }

      return;
    }

    console.warn("LiveStrean: can't join callObject is null");
  }, [callObject]);

  const leave = useCallback(async () => {
    if (callObject) {
      await callObject.leave();
    }
  }, [callObject]);

  const enablePip = async () => {
    if (!('pictureInPictureEnabled' in document)) {
      alert(
        'Picture-in-Picture API is not supported in this browser. You can try to do manually by clicking on the video and then clicking on the "Enter/Watch Picture-in-Picture" button.',
      );
      return;
    }

    if (videoRef.current) {
      try {
        await videoRef.current.requestPictureInPicture();
      } catch (error) {
        console.error('Failed to enter Picture-in-Picture mode:', error);
      }
    }
  };

  const exitPip = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture().catch(() => {});
    }
  };

  const joinElement = useMemo(() => {
    return (
      <button
        onClick={join}
        type="button"
        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
          />
        </svg>
        Start live streaming camera
      </button>
    );
  }, [join]);

  const leaveElement = useMemo(() => {
    return (
      <>
        <div className="flex justify-center">
          <video ref={videoRef} width={360} height={200} className="sm:rounded-md" autoPlay muted playsInline />
        </div>
        <div className="mt-4 flex justify-center gap-x-2">
          <button
            onClick={leave}
            type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 01-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 00-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409"
              />
            </svg>
            Stop live streaming camera
          </button>
          <button
            type="button"
            onClick={enablePip}
            className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <img
              width="25"
              height="25"
              style={{ mask: `url(${pipIcon}) center center no-repeat`, maskSize: '95%', backgroundColor: '#000' }}
            />
            Picture-In-Picture
          </button>
        </div>
      </>
    );
  }, [leave, videoTrack]);

  useEffect(() => {
    const obj = DailyIframe.createCallObject({ startAudioOff: true, startVideoOff: false });

    obj.on('joined-meeting', () => {
      setMeetingState(obj.meetingState());
    });

    obj.on('left-meeting', () => {
      setMeetingState(obj.meetingState());
    });

    setCallObject(obj);
  }, []);

  useEffect(() => {
    if (videoRef.current && videoTrack) {
      videoRef.current.srcObject = new MediaStream([videoTrack]);

      // enablePip();
    }

    return () => {
      exitPip();
    };
  }, [videoTrack]);

  if (meetingState !== 'joined-meeting') {
    return joinElement;
  } else {
    return leaveElement;
  }
};

export default LiveStream;
