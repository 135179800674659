import React from 'react';

const LiveReplayerSpinner = (props) => {
  return (
    <div className="live_replay_spinner">
      <svg
        className="mx-auto -ml-1 mr-3 h-5 w-5 animate-spin text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="20"
        height="20"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#E5464F" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="#E5464F"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span>{`sessionId=${props.sessionId} has started a session...waiting for events.`}</span>
    </div>
  );
};

export default LiveReplayerSpinner;
