import React, { useEffect, useRef, useState } from 'react';
import SessionStackScreen from './SessionStackScreen';
import SessionStackSessions from '../../web_components/session-stack/session-stack-sessions';

// Only this many screens will be rendered for performance and noise reasons.
const SESSIONS_LIMIT = 10;

/**
 * Renders a grid of web visitor screens from SessionStack.
 */
function SessionStackScreensGrid(props) {
  const [activeSessions, setActiveSessions] = useState([]);
  const [filteredPeopleIds, setFilteredPeopleIds] = useState([]);
  const originalTitle = useRef(null);

  const updateSessions = (event) => {
    console.debug('SessionStackScreensGrid updateSessions', event.detail);
    const newSessions = Object.values(event.detail);
    setActiveSessions((previousSessions) => newSessions);
  };

  /**
   * Only sessions that are contained w/peopleIds will be rendered.
   */
  const updateFilteredPeopleIds = (event) => {
    const newPeopleIds = event.detail;
    console.debug('SessionStackScreensGrid updateFilteredPeopleIds=', newPeopleIds);
    setFilteredPeopleIds(newPeopleIds);
  };

  /**
   * Returns an Array of sessions that are in the associated segment.
   * If a segment isn't set, returns the original sessions.
   */
  const filterSessions = (sessions) => {
    if (!props.segment) {
      return sessions;
    }
    console.debug('SessionStackScreensGrid filterSessions filteredPeopleIds=', filteredPeopleIds);
    return sessions.filter((session) => filteredPeopleIds.includes(SessionStackSessions.personIdFromSession(session)));
  };

  /*
   * Returns a trimmed array of sessions that fits within the limit we render.
   */
  const trimSessions = (sessions) => {
    return sessions.slice(0, SESSIONS_LIMIT);
  };

  /**
   * Listens for this event dispatches by the session-stack-sessions web component
   * to update the display as sessions are created / updated / deleted.
   */
  const addSessionActivityEventListeners = () => {
    window.addEventListener('session-stack:sessions:changed', updateSessions);
  };

  /**
   * Listen for event dispatches by the segment current people filter.
   */
  const addSegmentCurrentPeopleUpdatedEventListener = () => {
    window.addEventListener('segment:people:updated', updateFilteredPeopleIds);
  };

  const handleScreenClick = (personId) => {
    window.location = `/sites/${props.siteId}/live_view/${personId}/person`;
  };

  // Update the page title to include the count of rendered active sessions.
  const updatePageTitle = () => {
    const title = `${originalTitle.current} (${renderedScreens().length})`;
    document.title = title;
  };

  const renderedScreens = () => {
    return document.querySelectorAll('.gridContainer');
  };

  useEffect(() => (originalTitle.current = document.title), []);

  /**
   * On mount listen for SessionStack session activity.
   */
  useEffect(addSessionActivityEventListeners, []);

  useEffect(addSegmentCurrentPeopleUpdatedEventListener, []);

  /**
   * Regularly update the page title to include the number of rendered screens.
   */
  useEffect(() => {
    setInterval(updatePageTitle, 1000);
  }, []);

  const sessionsToRender = trimSessions(filterSessions(activeSessions));

  console.debug('SessionStackScreensGrid rendering=', sessionsToRender);

  if (sessionsToRender.length) {
    return (
      <div className="LiveReplayer grid grid-cols-1 gap-4 md:grid-cols-2">
        {sessionsToRender.map((session) => (
          <SessionStackScreen
            key={`${session.id}`}
            siteId={props.siteId}
            websiteId={props.websiteId}
            session={session}
            onClick={handleScreenClick}
            replayToken={props.replayToken}
            segmentId={props.segmentId}
          />
        ))}
      </div>
    );
  } else {
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Waiting for an active person...</h3>
      </div>
    );
  }
}

export default SessionStackScreensGrid;
