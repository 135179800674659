import React from 'react';
import Pluralize from 'react-pluralize';

const PreRenderPlaceholder = (props) => {
  if (!props.renderedSessionsCount) {
    return (
      <div className="relative col-span-2 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          <Pluralize singular={'person'} plural={'people'} count={props.activeSessions.length} /> on the site.
          Attempting to connect...
        </h3>
      </div>
    );
  } else {
    return null;
  }
};

export default PreRenderPlaceholder;
