import React, { useEffect, useRef, useState } from 'react';
import useFetch from 'use-http';
import { Replayer } from 'rrweb';
import Spinner from './LiveReplayerSpinner';
import DocVisible from './LiveReplayerDocumentVisibility';
import LastActive from './LiveReplayerLastActive';
import Call from './Call';
import IndiciaHeader from './IndiciaHeader';

// Max height (in px) to use for screen.
const INITIAL_HEIGHT = 600;

/*
 * React function compontent to render a live view for a particular person.
 * This is based on LiveReplayerComponent and is very non-DRY.
 */

function ScreenView(props) {
  const [replayer, initReplayer] = useState();
  const [activePerson, setActivePerson] = useState(false);
  const [events, setEvents] = useState([]);
  // Indicia is an Object with information about the person associated
  // w/the session (email, ip address, device, etc).
  const [indicia, setIndicia] = useState({ attributes: {}, device_icons: {}, utm_params: {} });
  const { cache, get, response } = useFetch();
  const [initialWidth, setInitialWidth] = useState();
  const [initialHeight, setInitialHeight] = useState();
  const [iFrameSize, setIframeSize] = useState({ width: null, height: null });
  const [documentVisible, setDocumentVisible] = useState();
  const [activePanel, setActivePanel] = useState('screen');
  const [sessionChannel, setSessionChannel] = useState();
  const [hasEvents, setHasEvents] = useState(props.hasEvents);

  const refTimestamp = useRef(null);

  const screenContainerEl = useRef(null); // .livePersonContainer

  /*
   * Given `width` and `height` params:
   * 1. resizes the .replayer_wrapper div (parent to the iframe) so its aspect
   *   ratio matches the width x height of the event.
   * 2. scales the content of the iframe so the entire window fits within the iframe.
   */
  const scaleIframeSize = (width, height) => {
    // Need to find this element in the DOM as it is added when the rrweb player is
    // initialized.
    if (!replayer || !replayer.wrapper) {
      return;
    }
    const replayerWrapper = replayer.wrapper;

    const screenRatio = (width, height) => {
      // calculate the screen width and height ratios to the the containers they need to fit.
      const widthRatio = parseFloat((1 / (width / initialWidth)).toFixed(2));
      const heightRatio = parseFloat((1 / (height / initialHeight)).toFixed(2));
      // determine the scaling ratio to use when displaying the user's screen.
      // use the smallest scale ratio btw the width height. for example, if on a very wide screen,
      // the width ratio will be the smallest as it has to shrink the most.
      return Math.min(widthRatio, heightRatio).toFixed(2);
    };
    const scaleValue = screenRatio(width, height);
    console.debug(
      `Screen width=${width} height=${height} scaleValue=${scaleValue}, initialWidth=${initialWidth}, initialHeight=${initialHeight}`,
    );
    // Set the styles
    replayerWrapper.style.width = `${width * scaleValue}px`;
    replayerWrapper.style.height = `${height * scaleValue}px`;
    replayerWrapper.style.transform = `scale(${scaleValue})`;
  };

  useEffect(() => {
    scaleIframeSize(iFrameSize.width, iFrameSize.height);
  }, [iFrameSize]);

  useEffect(() => {
    setSessionChannel(props.ably.channels.get(props.channelName));
  }, []);

  const setupChannel = () => {
    if (!sessionChannel) {
      return;
    }
    sessionChannel.presence.enter({ userType: 'operator' }, function (err) {
      if (err) {
        return console.error(err);
      }
      console.debug('[Glass.io] Successfully present on the channel=', sessionChannel.name);
    });
  };
  useEffect(setupChannel, [sessionChannel]);

  // Init Replayer instance
  useEffect(() => {
    if (!hasEvents) {
      return;
    }
    const newReplayer = new Replayer([], {
      liveMode: true,
      root: screenContainerEl.current,
      // otherwise the iframe will steak focus from outside page elements (like the chat form)
      mouseTail: false,
      triggerFocus: false,
    });
    initReplayer(newReplayer);
    props.onInitReplayer(props.sessionId);
    // See https://github.com/rrweb-io/rrweb/blob/de755ae577ac361dd1883a89d2597712b6a9cf93/guide.md
    // for events.
  }, [hasEvents]);

  useEffect(() => {
    if (!screenContainerEl.current) {
      return;
    }
    console.debug(
      'Setting initial width and height from screenContainerEl=',
      screenContainerEl,
      'width:',
      screenContainerEl.current.clientWidth,
      'height=',
      screenContainerEl.current.clientHeight,
    );
    setInitialWidth(screenContainerEl.current.clientWidth);
    setInitialHeight(INITIAL_HEIGHT);
  }, [hasEvents]);

  useEffect(() => {
    if (hasEvents) {
      console.debug('Received first event. sessionId=', props.sessionId);
    }
  }, [hasEvents]);

  /*
   * Fetch indicia about the person associated with the session id.
   * Update the `indicia` state with the result.
   */
  const getIndica = async () => {
    if (props.disableFetch) {
      return;
    }
    const newIndicia = await get(`/sites/${props.siteId}/people/${props.sessionId}/indicia`);
    if (response.ok) {
      console.debug('indicia=', newIndicia);
      // Record when last rendered to help identify users that are not
      // interacting w/the site.
      newIndicia.rendered_at = new Date();
      newIndicia.first_seen_at = new Date(newIndicia.first_seen_at);
      setIndicia(newIndicia);
    }
  };

  const getNewEvents = async () => {
    cache.clear();
    if (props.disableFetch) {
      // still want to render the component.
      if (!activePerson) {
        setActivePerson(true);
        setHasEvents(true);
      }
      return;
    }
    const newEvents = await get(`/api/events/${props.sessionId}/${refTimestamp.current || ''}`);
    if (response.ok && newEvents.length) {
      if (!hasEvents) {
        setHasEvents(true);
      }
      refTimestamp.current = newEvents[newEvents.length - 1].timestamp;
      console.debug('Set new refTimestamp=', new Date(refTimestamp.current));
      setEvents(newEvents);
    }
  };

  // Add events to the replayer when they change.
  useEffect(() => {
    if (events.length > 0) {
      if (!activePerson) {
        console.debug(
          'Starting replayer at timestamp=',
          new Date(events[0].timestamp),
          'refTimestamp=',
          new Date(refTimestamp.current),
        );
        replayer.startLive(events[0].timestamp - 200);
        setActivePerson(true);
      }

      for (const event of events) {
        // Scale iframe size to fit in the parent container
        if (event.data && event.data.width && event.data.height) {
          // extract just width and height into a new object
          const newSize = (({ width, height }) => ({ width, height }))(event.data);
          setIframeSize(newSize);
        }
        if (event.type === 5 && event.data.tag === 'document-visible') {
          setDocumentVisible(event.data.payload.visible);
        }
        if (props.disableReplay) {
          // Testing not rendering events to see impact on performance
          console.debug('[IGNORE]. props.disableReplay=TRUE event=', event);
        } else {
          // console.debug(`Adding event to replayer. Age=${(new Date() - new Date(event.timestamp))/1000} seconds`, {session_id: props.sessionId, type: event.type, timestamp: event.timestamp})
          replayer.addEvent(event);
        }
      }
    }
  }, [events]);

  useEffect(() => {
    getNewEvents();
    getIndica();
  }, []);

  const subscribe = () => {
    console.debug('Subscribing to sessionChannel new-events=', props.sessionId);
    // When a new-events message is received, update the displayed indicia and
    // fetch new events to play in the live replayer.
    sessionChannel.subscribe('new-events', async function (msg) {
      // console.debug("new-events message received from channel=", sessionChannel)
      await getIndica();
      await getNewEvents();
    });
  };

  useEffect(() => {
    if (!sessionChannel) {
      return;
    }
    subscribe();

    return function cleanup() {
      console.debug('LiveReplayerComponent cleanup. sessionId=', props.sessionId);
      sessionChannel.detach();
      sessionChannel.on('detached', function (stateChange) {
        console.debug('Releasing channel=', props.sessionId);
        // enable garbage collection
        props.ably.channels.release(props.sessionId);
        props.onCleanup(props.sessionId);
      });
    };
  }, [sessionChannel]);

  const handleToggleClick = (event) => {
    const panel = event.target.closest('button').name;
    setActivePanel(panel);
  };

  if (hasEvents) {
    return (
      <div
        className={
          'screen-view-container flex flex-col justify-between divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow'
        }
      >
        <div
          className={`${
            activePanel != 'screen' ? 'hidden' : ''
          } livePersonContainer single-person w-100% flex grow justify-center ${
            activePerson ? '' : 'liveReplayerSpinner'
          }`}
          ref={screenContainerEl}
        >
          {!activePerson && <Spinner sessionId={props.sessionId} />}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

ScreenView.defaultProps = {
  showHeader: true,
  showFooter: true,
};

export default ScreenView;
