import React, { useEffect } from 'react';
import DeviceTypeIcon from './DeviceTypeIcon';

const IndiciaToggle = (props) => {
  return (
    <span className="relative z-0 ml-4 inline-flex rounded-md bg-gray-100 shadow-sm">
      <button
        name="screen"
        type="button"
        onClick={props.onButtonClick}
        className={`${
          props.active == 'screen' ? 'bg-white' : ''
        } relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-2 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500`}
      >
        <DeviceTypeIcon name={props.deviceType} />
      </button>
      <button
        name="indicia"
        type="button"
        onClick={props.onButtonClick}
        className={`${
          props.active == 'indicia' ? 'bg-white' : ''
        } relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-2 py-2 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500`}
      >
        <span className="sr-only">Indicia</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
          />
        </svg>
      </button>
    </span>
  );
};

export default IndiciaToggle;
