import moment from 'moment';
require('moment-timezone');

/*
 * Encapsulates view-related javascript handling of time
 * (setting a timezone, rendering JS dates in the tz, formatting JS Dates).
 *
 * When initialized, the user's timezone name (a string) is retrieved and stored in
 * a "time_zone" cookie. This cookie value can be accesssed for server-side rendering.
 *
 * Usage for client-side rendering:
 * - Add a "data-js-date" attribute to an element with an iso8601-formatted string.
 *   Example: `<span data-js-date="<%= status.created_at.iso8601 %>"></span>`
 * - Trigger a "apply-time-formatting" event, which calls the `applyAll()` function and
 *   converts the string into a moment object, applies the user's timezone, and formats the time,
 *   rendering it within the element.
 */

// Name of the cookie to store the user timezone name (String).
const COOKIE_NAME = 'time_zone';

// Name of the data attribute used to apply dates
const DATA_JS_DATE = 'data-js-date';

export default class {
  constructor() {
    // Get the user time zone name
    this.name = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.setCookie();

    const _this = this;
  }

  // Store the timezone name in a cookie for 1 year
  setCookie() {
    document.cookie =
      `${COOKIE_NAME}=` + this.name + '; expires=' + this.cookieExpireAt() + '; path=/' + '; SameSite=Strict';
    console.debug('Storing timezone in cookie=', this.name);
  }

  // 1 year from now
  cookieExpireAt() {
    // https://stackoverflow.com/questions/8609261/how-to-determine-one-year-from-now-in-javascript
    return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  }

  /*
   * Returns a moment object representation of a date string in iso8601 format
   * within the user's timezone.
   *
   * If the tz is invalid, the moment object in the original timezone is returned.
   */
  inZone(date_iso8601) {
    const momentDate = this.toMoment(date_iso8601);
    return momentDate.tz(this.name);
  }

  // Converts an iso8601-formated string into a moment object.
  toMoment(date_iso8601) {
    return moment(date_iso8601);
  }
}
