// Max number of sessions to return.
const LIMIT = 500;

// Interval in ms to poll for active sessions
const POLL_INTERVAL_MS = 5 * 1000;

// If a session hasn't been active in this length of time it is considered
// inactive and deleted.
const STALE_THRESHOLD_IN_MINUTES = 3;

// If a session is shorter than this amount, the session is not added.
// Player has issues with short sessions.
const MIN_SESSION_LENGTH_TO_ADD_MS = 10 * 1000;

// Non-live sessions shorter than this threshold are removed.
const SHORT_SESSION_THRESHOLD_MS = 60 * 1000;

// https://docs.session-stack.com/reference/websiteswebsite_idsessions

/*
 * Polls for live SessionStack sessions every `POLL_INTERVAL_MS`, generating
 * events when sessions are created/updated/and deleted.
 */
export default class SessionStackSessions extends HTMLElement {
  constructor() {
    super();
    console.debug('<session-stack-sessions> initialized');
    // key == person id, value = session stack session
    this.activeSessions = {};
  }

  static get observedAttributes() {
    return [];
  }

  static staleDate() {
    return new Date(new Date() - 60 * 1000 * STALE_THRESHOLD_IN_MINUTES);
  }

  connectedCallback() {
    this.startPoll();
  }

  get websiteId() {
    return this.getAttribute('website-id');
  }

  /**
   * Returns true if restricting to live sessions.
   */
  get live() {
    return this.getAttribute('live') === 'true';
  }

  get encodedAuthToken() {
    return this.getAttribute('encoded-auth-token');
  }

  /**
   * Processes the api response, updating the activeSessions lookup object.
   */
  processResponse(sessionsData) {
    console.debug(
      '<session-stack-sessions> isLive=',
      sessionsData.filter((session) => session.isLive),
    );
    // api sort+order params not working. manually sorting.
    sessionsData.sort((a, b) => b.last_active - a.last_active);
    this.updateExistingSessions(sessionsData);
    this.removeDeletedSessions(sessionsData);
    this.addNewSessions(sessionsData);
    // Be more selective when restricting to live sessions.
    // In non-live cases, we want to show something if we can.
    if (this.live) {
      this.removeShortSessions();
      this.removeStaleSessions();
    }
  }

  /**
   * Dispatches a "session-stack:sessions:changed" when ANY of the live sessions are changed.
   * The payload is the `activerSessions` lookup object.
   */
  dispatchSessionsChanged() {
    return this.dispatchEvent(
      new CustomEvent('session-stack:sessions:changed', { bubbles: true, composed: true, detail: this.activeSessions }),
    );
  }

  /*
   * Updates existing sessions and dispatches an event.
   */
  updateExistingSessions(sessionsData) {
    for (const [key, value] of Object.entries(this.activeSessions)) {
      const session = sessionsData.find((session) => this.constructor.personIdFromSession(session) == key);
      if (session) {
        // Don't update if the session in the lastest sessionsData
        // is <= the currently stored session.
        if (session.last_active < value.last_active) return;
        // Check if too short.
        if (session.isLive && session.length < MIN_SESSION_LENGTH_TO_ADD_MS) {
          console.debug(
            '<session-stack-sessions> Not updating short live session=',
            session,
            'length in sec=',
            session.length / 1000,
          );
          return;
        }
        this.activeSessions[key] = session;
        //  console.debug("<session-stack-sessions> updated=",session)
        this.dispatchEvent(
          new CustomEvent('session-stack:session:updated', { bubbles: true, composed: true, detail: session }),
        );
        this.dispatchPersonActiveStatusEvent(session);
        this.dispatchSessionsChanged();
      }
    }
  }

  /*
   * Removes deleted sessions and dispatches an event.
   *
   * For lower traffic sites, `sessionsData` is likely to hold more results than what is
   * stored in `this.activeSessions` and this is less likely to actually remove a session.
   */
  removeDeletedSessions(sessionsData) {
    for (const [key, value] of Object.entries(this.activeSessions)) {
      if (!sessionsData.find((session) => this.constructor.personIdFromSession(session) == key)) {
        console.debug('<session-stack-sessions> deleted=', value);
        this.dispatchEvent(
          new CustomEvent('session-stack:session:deleted', { bubbles: true, composed: true, detail: value }),
        );
        this.dispatchPersonActiveStatusEvent(value);
        delete this.activeSessions[key];
        this.dispatchSessionsChanged();
      }
    }
  }

  /*
   * Removes stale sessions and dispatches an event.
   */
  removeStaleSessions() {
    for (const [key, session] of Object.entries(this.activeSessions)) {
      if (session.last_active < SessionStackSessions.staleDate()) {
        console.debug('<session-stack-sessions> removeStaleSessions stale=', session);
        this.dispatchEvent(
          new CustomEvent('session-stack:session:stale', { bubbles: true, composed: true, detail: session }),
        );
        this.dispatchPersonActiveStatusEvent(session);
        delete this.activeSessions[key];
        this.dispatchSessionsChanged();
      } else {
        console.debug(
          '<session-stack-sessions> removeStaleSessions NOT stale=',
          session,
          'Seconds till stale=',
          (session.last_active - SessionStackSessions.staleDate()) / 1000,
        );
      }
    }
  }

  static personIdFromSession(session) {
    return session?.userIdentity?.identifier;
  }

  dispatchPersonActiveStatusEvent(session) {
    const personId = this.constructor.personIdFromSession(session);
    if (!personId) return;
    this.dispatchEvent(
      new CustomEvent(`person:status:${session.isLive ? 'active' : 'inactive'}`, {
        bubbles: true,
        composed: true,
        detail: { person_id: personId },
      }),
    );
  }

  /*
   * Removes short sessions that are no longer live and dispatches an event.
   */
  removeShortSessions() {
    for (const [key, session] of Object.entries(this.activeSessions)) {
      if (!session.isLive && session.length < SHORT_SESSION_THRESHOLD_MS) {
        console.debug(
          '<session-stack-sessions> removeShortSessions session=',
          session,
          'length (sec)=',
          session.length / 1000,
        );
        this.dispatchEvent(
          new CustomEvent('session-stack:session:short', { bubbles: true, composed: true, detail: session }),
        );
        this.dispatchPersonActiveStatusEvent(session);
        delete this.activeSessions[key];
        this.dispatchSessionsChanged();
      }
    }
  }

  /*
   * Adds new live sessions and dispatches an event.
   */
  addNewSessions(sessionsData) {
    sessionsData.forEach((session) => {
      const key = this.constructor.personIdFromSession(session);
      // already exists
      if (this.activeSessions[key]) return;
      // Check if too short.
      if (session.isLive && session.length < MIN_SESSION_LENGTH_TO_ADD_MS) {
        console.debug(
          '<session-stack-sessions> Not adding short live session=',
          session,
          'length in sec=',
          session.length / 1000,
        );
        return;
      }
      // not live
      if (this.live && !session.isLive) return;
      this.activeSessions[key] = session;
      console.debug('<session-stack-sessions> created=', session);
      this.dispatchEvent(
        new CustomEvent('session-stack:session:created', { bubbles: true, composed: true, detail: session }),
      );
      this.dispatchPersonActiveStatusEvent(session);
      this.dispatchSessionsChanged();
    });
  }

  /**
   * The API url for fetching sessions.
   */
  get url() {
    // order and sort do not appear to work.
    const url = `https://api.sessionstack.com/v1/websites/${this.websiteId}/sessions?limit=${LIMIT}`;
    return url;
  }

  /**
   * Polls the `url` every `POLL_INTERVAL_MS` for sessions, updating the `activeSessins` lookup object.
   */
  startPoll() {
    if (!this.websiteId.length || !this.encodedAuthToken) {
      console.error('<session-stack-sessions> required attributes not set. Not polling.');
      setTimeout(() => {
        this.startPoll();
      }, POLL_INTERVAL_MS);
      return;
    }
    fetch(this.url, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${window.sessionStackConfig.encodedAuthToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.debug('<session-stack-sessions> poll response=', data);
        this.processResponse(data.data);
        setTimeout(() => {
          this.startPoll();
        }, POLL_INTERVAL_MS);
      })
      .catch((err) => {
        console.error('<session-stack-sessions> poll error=', err);
        setTimeout(() => {
          this.startPoll();
        }, POLL_INTERVAL_MS);
      });
  }
}

export const defineCustomElement = () => customElements.define('session-stack-sessions', SessionStackSessions);
