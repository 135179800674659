/*
 * Updates the Favicon to display the active/inactive
 * status of the person with /data-person-id.
 *
 * Requires that the monitor-active-people or session-stack-sessions component is connected.
 */
export default class PersonActiveStatusFavicon extends HTMLElement {
  constructor() {
    super();
    console.debug('<person-active-status-favicon> initialized');
  }

  static get observedAttributes() {
    return ['active'];
  }

  connectedCallback() {
    console.debug(
      '<person-active-status-favicon> connected. data-person-id=',
      this.getAttribute('data-person-id'),
      this,
    );
    document.addEventListener('person:status:active', (e) => {
      this.handleActive(e);
    });
    document.addEventListener('person:status:inactive', (e) => {
      this.handleInActive(e);
    });
  }

  handleActive(e) {
    if (e.detail.person_id !== this.getAttribute('person-id')) return;
    this.renderActive();
  }

  handleInActive(e) {
    if (e.detail.person_id !== this.getAttribute('person-id')) return;
    this.renderInactive();
  }

  removeFaviconElements() {
    // https://stackoverflow.com/questions/13125817/how-to-remove-elements-that-were-fetched-using-queryselectorall
    if (!NodeList.prototype.forEach && Array.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    // ..then continue as usual with the forEach
    const els = document.querySelectorAll('link.person-favicon');
    els.forEach((e) => e.parentNode.removeChild(e));
    return els;
  }

  renderActive() {
    this.removeFaviconElements();
    return document.querySelector('head').insertAdjacentHTML(
      'beforeend',
      `
        <link class="person-favicon" rel="icon" type="image/png" sizes="32x32" href="/favicon-online/favicon-32x32.png">
        <link class="person-favicon" rel="icon" type="image/png" sizes="16x16" href="/favicon-online/favicon-16x16.png">
        `,
    );
  }

  renderInactive() {
    this.removeFaviconElements();
    return document.querySelector('head').insertAdjacentHTML(
      'beforeend',
      `
        <link class="person-favicon" rel="icon" type="image/png" sizes="32x32" href="/favicon-offline/favicon-32x32.png">
        <link class="person-favicon" rel="icon" type="image/png" sizes="16x16" href="/favicon-offline/favicon-16x16.png">
        `,
    );
  }
}

export const defineCustomElement = () =>
  customElements.define('person-active-status-favicon', PersonActiveStatusFavicon);
