import React, { useEffect, useState } from 'react';
import SessionStackScreenHeader from './SessionStackScreenHeader';
import SessionStackPlayerEmbed from './SessionStackPlayerEmbed';
import TimeAgo from 'react-timeago';

export default function SessionStackScreen(props) {
  const [indicia, setIndicia] = useState({ attributes: {}, device_icons: {}, utm_params: {} });

  const personId = () => {
    return props.session.userIdentity.identifier;
  };

  const indiciaUrl = () => {
    const url = `/sites/${props.siteId}/people/${personId()}/indicia`;
    return url;
  };

  const getIndica = () => {
    fetch(indiciaUrl())
      .then((response) => {
        if (!response.ok) {
          throw new Error('<session-stack-screen> getIndica response not success.', { cause: { response } });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        console.debug('<session-stack-screen> getIndica data=', data);
        setIndicia(data);
      })
      .catch((err) => {
        console.error('<session-stack-screen> getIndica error=', err);
      });
  };

  const onClick = (event) => {
    props.onClick(personId());
  };

  /**
   * Get indicia when mounted.
   */
  useEffect(getIndica, []);

  if (!indicia.attributes.id) {
    console.warn('SessionStackScreen rendering error. No indicia. session=', props.session);
    return null;
  }

  console.debug('SessionStackScreen rendering session=', props.session);

  return (
    <div
      onClick={onClick}
      className="gridContainer flex flex-col justify-between divide-y divide-gray-200 overflow-hidden rounded-lg border border-2 border-transparent bg-white shadow hover:cursor-pointer hover:border-indigo-400"
    >
      <SessionStackScreenHeader indicia={indicia} session={props.session} siteId={props.siteId} />

      <div className="livePersonContainer w-100% flex grow justify-center">
        <SessionStackPlayerEmbed websiteId={props.websiteId} session={props.session} replayToken={props.replayToken} />
      </div>

      {/* Footer */}
      <div className="flex items-center justify-between px-4 py-4 sm:px-6">
        <div className="ml-4 flex-shrink-0 text-xs text-gray-500">
          <span>
            {indicia.attributes.visits_count} {`${indicia.attributes.visits_count == 1 ? 'visit' : 'visits'}`}
          </span>
          &nbsp;•&nbsp;
          <span>{indicia.attributes.last_messaged_at_to_s}</span>
          &nbsp;•&nbsp;
          <span>{indicia.attributes.last_responded_at_to_s}</span>
          &nbsp;•&nbsp; Started <TimeAgo date={props.session.client_start} />
        </div>
      </div>
    </div>
  );
}
