/*
 * Wraps around a PersonIcon component to display the active/inactive
 * status of the person with /data-person-id.
 *
 * Requires that the monitor-active-people or session-stack-sessions component is connected.
 */
export default class PersonActiveStatus extends HTMLElement {
  constructor() {
    super();
    console.debug('<person-active-status> initialized');
  }

  static get observedAttributes() {
    return ['active'];
  }

  connectedCallback() {
    console.debug('<person-active-status> connected. data-person-id=', this.getAttribute('data-person-id'), this);
    this.el = document.createElement('span');
    this.el.setAttribute('class', 'online-status');
    this.appendChild(this.el);
    this.updateStatus();
    document.addEventListener('person:status:active', (e) => {
      this.handleActive(e);
    });
    document.addEventListener('person:status:inactive', (e) => {
      this.handleInActive(e);
    });
  }

  disconnectedCallback() {
    console.debug('<person-active-status> disconnected. data-person-id=', this.getAttribute('data-person-id'), this);
    document.removeEventListener('person:status:active', (e) => {
      this.handleActive(e);
    });
    document.removeEventListener('person:status:inactive', (e) => {
      this.handleInActive(e);
    });
  }

  handleActive(e) {
    if (e.detail.person_id !== this.getAttribute('data-person-id')) return;
    //  console.debug("<person-active-status> Handling active person=",e.detail.person_id)
    this.active = true;
  }

  handleInActive(e) {
    if (e.detail.person_id !== this.getAttribute('data-person-id')) return;
    // console.debug("<person-active-status> Handling inactive person=",e.detail.person_id)
    this.active = false;
  }

  set active(newValue) {
    //    console.debug("<person-active-status> setActive. data-person-id=", this.getAttribute("data-person-id"), newValue)
    this.setAttribute('active', newValue);
  }

  get active() {
    let res = null;
    if (this.getAttribute('active') === 'true') {
      res = true;
    } else if (this.getAttribute('active') === 'false') {
      res = false;
    }
    return res;
  }

  get person() {
    return JSON.parse(this.getAttribute('person'));
  }

  set person(newValue) {
    this.setAttribute('person', JSON.stringify(newValue));
  }

  get statusClass() {
    let css = 'hidden';
    if (this.active) {
      css = '';
    } else if (this.active === false) {
      css = 'inactive';
    }
    return css;
  }

  // Can't just re-render as that will lose child elements.
  updateStatus() {
    this.el.setAttribute('class', 'online-status'); // reset
    if (!this.statusClass) return;
    this.el?.classList?.add(this.statusClass);
  }

  attributeChangedCallback(attrName, oldValue, newValue) {
    if (oldValue == newValue) return;
    console.debug(
      '<person-active-status> attributeChangedCallback() person=',
      this.getAttribute('data-person-id'),
      attrName,
      oldValue,
      newValue,
    );
    this.updateStatus();
  }
}

export const defineCustomElement = () => customElements.define('person-active-status', PersonActiveStatus);
