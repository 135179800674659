const VISITOR_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
  <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
  <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
</svg>`;

const AUTHED_USER_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd" />
</svg>`;

const BOT_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
</svg>`;

export default class PersonIcon extends HTMLElement {
  constructor() {
    super();
    console.debug('<person-icon> initialized');
  }

  connectedCallback() {
    console.debug('<person-icon> connectedCallback');
    this.render();
  }

  get type() {
    return this.getAttribute('type');
  }

  get avatar_url() {
    const raw = this.getAttribute('avatar_url');
    return raw && raw.length && raw != '' ? raw : null;
  }

  get icon() {
    if (this.avatar_url) {
      return `<img src="${this.avatar_url}" />`;
    }
    switch (this.type) {
      case 'Bot':
        return BOT_ICON;
      case 'AuthedUser':
        return AUTHED_USER_ICON;
      default:
        return VISITOR_ICON;
    }
  }

  render() {
    this.innerHTML = `
      ${this.icon}
    `;
  }
}

export const defineCustomElement = () => customElements.define('person-icon', PersonIcon);
