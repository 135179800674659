/*
 * JS logic for TailwindCSS dropdowns.
 * https://tailwindui.com/components/application-ui/elements/dropdowns
 */
export default class Dropdown {
  static initAll() {
    const dropdowns = this.findAll();
    dropdowns.forEach((dd) => {
      new Dropdown(dd);
    });
  }

  static findAll() {
    return document.querySelectorAll('.dropdown');
  }

  constructor(el) {
    this.button = el.querySelector('.dropdown-toggle');
    this.menu = el.querySelector('.dropdown-menu');
    this.addEvents();
  }

  hideOthers() {
    document.querySelectorAll('.dropdown-menu').forEach((menu) => {
      if (menu !== this.menu) {
        menu.classList.add('hidden');
      }
    });
  }

  addEvents() {
    this.button.addEventListener('click', (event) => {
      if (this.menu.classList.contains('hidden')) {
        this.menu.classList.remove('hidden');
        this.hideOthers();
      } else {
        this.menu.classList.add('hidden');
      }
      event.stopPropagation();

      document.body.addEventListener('click', () => {
        if (!this.menu.classList.contains('hidden')) {
          this.menu.classList.add('hidden');
        }
      });
    });
  }
}
