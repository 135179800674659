/* eslint-disable import/first */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

if (process.env.RAILS_ENV !== 'production') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import Dropdown from 'custom/dropdown';
import TimeUtil from 'custom/time_util';
import resizeDebounce from 'custom/resize_debounce';

// components
import { defineCustomElement as defineTabTray } from 'web_components/tab-tray';
import { defineCustomElement as defineTabItem } from 'web_components/tab-item';
import { defineCustomElement as definePersonActiveStatus } from 'web_components/person-active-status';
import { defineCustomElement as definePersonActiveStatusFavicon } from 'web_components/person-active-status-favicon';
import { defineCustomElement as definePersonIcon } from 'web_components/person-icon';
import { defineCustomElement as defineMonitorActivePeople } from 'web_components/monitor-active-people';
import { defineCustomElement as defineMonitorSegmentCurrentPeople } from 'web_components/monitor-segment-current-people';
import { defineCustomElement as defineSessionStackSessions } from 'web_components/session-stack/session-stack-sessions';

import FlashTitle from 'custom/flash_title';

defineTabTray();
defineTabItem();
definePersonActiveStatus();
definePersonActiveStatusFavicon();
definePersonIcon();
defineMonitorActivePeople();
defineMonitorSegmentCurrentPeople();
defineSessionStackSessions();

const timeUtil = new TimeUtil();
global.timeUtil = timeUtil;

Rails.start();
ActiveStorage.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

window.document.addEventListener('DOMContentLoaded', () => {
  // Init dropdowns
  Dropdown.initAll();

  // Init Flash title
  window.flashTitle = new FlashTitle();
});

resizeDebounce();
