// debounced window resize event (resize:done)
// https://web.archive.org/web/20220714020647/https://bencentra.com/code/2015/02/27/optimizing-window-resize.html
export default function resizeDebounce() {
  window.addEventListener('resize', function () {
    // clear the timeout
    clearTimeout(timeout);
    // start timing for event "completion"
    const timeout = setTimeout(() => {
      window.dispatchEvent(new CustomEvent('resize:done'));
    }, 250);
  });
}
