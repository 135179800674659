import React, { useEffect, useRef, useState } from 'react';
import SessionStackPlayerEmbed from './SessionStackPlayerEmbed';
import SessionStackSessions from '../../web_components/session-stack/session-stack-sessions';

/*
 * Renders the most recent session. used on live_view/people to show just their session.
 */
export default function SessionStackMostRecentSession(props) {
  const [session, setSession] = useState(props.session);

  /**
   * Handler for new sessions
   */
  const updateSession = (event) => {
    const forPerson = Object.values(event.detail).filter(
      (sess) => props.personId == SessionStackSessions.personIdFromSession(sess),
    );
    const mostRecent = forPerson.sort((a, b) => b.last_active - a.last_active)[0];
    // Don't replace the existing if a session isn't found. This is the initial loading state as
    // sessions are loaded via an XHR request.
    if (!mostRecent) {
      console.debug(
        'SessionStackMostRecentSession updateSession no recent session found. Keeping existing session=',
        session,
      );
      return;
    }
    // console.debug("SessionStackMostRecentSession updateSession mostRecent=", mostRecent)
    setSession(mostRecent);
  };

  const addSessionActivityEventListeners = () => {
    window.addEventListener('session-stack:sessions:changed', updateSession);
  };

  /**
   * On mount listen for SessionStack session activity.
   */
  useEffect(addSessionActivityEventListeners, []);

  const oldSessionNotice = () => {
    if (session.isLive) {
      return;
    }
    // based on _flash_messages
    return (
      <div className="mb-5 rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-6 text-blue-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">No longer online. Showing most recent visit.</p>
          </div>
        </div>
      </div>
    );
  };

  // console.debug("SessionStackMostRecentSession rendering=", session)

  if (!session) {
    console.warn('SessionStackMostRecentSession rendering. No session. Not rendering.');
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Waiting for this person to become active...</h3>
      </div>
    );
  }

  return (
    <div className="recent-session-viewer flex w-full flex-col">
      {oldSessionNotice()}
      <SessionStackPlayerEmbed session={session} replayToken={props.replayToken} />
    </div>
  );
}
