import React from 'react';
import Firefox from 'images/device_icons/browser/firefox.svg';
import Chrome from 'images/device_icons/browser/chrome.svg';
import Safari from 'images/device_icons/browser/safari.svg';
import Edge from 'images/device_icons/browser/edge.svg';

export default function BrowserIcon(props) {
  const iconSrc = (name) => {
    let res = null;
    switch (name) {
      case 'firefox':
        res = Firefox;
        break;
      case 'chrome':
        res = Chrome;
        break;
      case 'safari':
        res = Safari;
        break;
      case 'edge':
        res = Edge;
        break;
    }
    return res;
  };

  if (props.name) {
    return <img src={iconSrc(props.name)} className="device-icon ml-2 w-4" />;
  } else {
    return null;
  }
}
