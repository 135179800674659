import React from 'react';
import TimeAgo from 'react-timeago';

const LiveReplayerLastActive = (props) => {
  if (props.date) {
    return (
      <span>
        Active <TimeAgo date={props.date} />
      </span>
    );
  } else {
    return null;
  }
};

export default LiveReplayerLastActive;
