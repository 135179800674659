import PersonActiveStatus from './person-active-status';
import PersonIcon from './person-icon';

const LIVE_VIEW_MENU_SHARED_CLASSES =
  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-400 group inline-flex items-center py-3 px-3 border-b-2 font-medium text-sm';
const LIVE_VIEW_MENU_DEFAULT_CLASSES = '';
const LIVE_VIEW_MENU_CURRENT_CLASSES = 'border-indigo-500 text-indigo-600 hover:border-indigo-500';

export default class TabItem extends HTMLElement {
  constructor() {
    super();
    console.debug('<tab-item> initialized');
  }

  static get observedAttributes() {
    return ['current', 'person'];
  }

  connectedCallback() {
    this.render();
  }

  setCSSClasses() {
    let classes = [LIVE_VIEW_MENU_SHARED_CLASSES];
    if (this.current == 'true') {
      classes = classes.concat(LIVE_VIEW_MENU_CURRENT_CLASSES);
    } else {
      classes = classes.concat(LIVE_VIEW_MENU_DEFAULT_CLASSES);
    }
    // keep hidden ... not an attribute as non-tab tray items use this class too
    if (this.classList.contains('mini')) {
      classes = classes.concat('mini');
    }
    this.setAttribute('class', classes.join(' '));
  }

  get current() {
    return this.getAttribute('current');
  }

  set current(newValue) {
    this.setAttribute('current', newValue);
  }

  get person() {
    return JSON.parse(this.getAttribute('person'));
  }

  set person(newValue) {
    this.setAttribute('person', JSON.stringify(newValue));
  }

  get tray() {
    return this.closest('tab-tray');
  }

  attributeChangedCallback(attrName, oldValue, newValue) {
    if (oldValue === newValue) return;
    console.debug('<tab-item> changed. person=', this.person, 'attrName=', attrName);
    // might not be in dom yet
    if (!this.tray) return;
    this.render();
  }

  render() {
    this.innerHTML = `
    <a href="${
      this.person.url
    }" class='person flex items-center' style="flex-basis: 90%; white-space: nowrap;" title="${this.person.label}">
      <div class="person_icon_container inline relative mr-1">
        <person-active-status data-person-id="${this.person.id}">
          <person-icon type="${this.person.type}"
                       avatar_url="${this.person.avatar_url ? this.person.avatar_url : ''}"></person-icon>
        </person-active-status>
        ${this.renderChatMessageIcon()}
        <!-- pointer -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 absolute -top-1 left-3 hidden">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
        </svg>
      </div>
      <span>${this.person.label}</span>
    </a>
    <a class="close" rel="nofollow" data-method="delete"
        href="${this.person.close_url}?current_person_id=${this.tray.current?.person?.id || ''}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="text-gray-600 group-hover:text-black ml-1 h-3 w-3">
        <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd"></path>
      </svg>
    </a>
    `;
    this.setCSSClasses();
  }

  renderChatMessageIcon() {
    if (!this.person.has_unseen_chat_messages) return '';
    return `
    <!-- chat message -->
      <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="new-message-icon w-4 h-4 absolute -top-2 -right-1">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
      </svg>
    `;
  }
}

export const defineCustomElement = () => customElements.define('tab-item', TabItem);
