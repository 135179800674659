import React, { useRef } from 'react';
import VideoRecorder from 'react-video-recorder';

export default function VideoRecorderUpload(props) {
  // todo next - add import, fix ref, useRef
  const avatarFile = useRef(document.getElementById('user_avatar'));

  return (
    <div style={{ width: '600px', height: '337px' }}>
      <VideoRecorder
        isFlipped={true}
        timeLimit={20000}
        showReplayControls
        disablePictureInPicture
        onRecordingComplete={(videoBlob) => {
          // Do something with the video...
          console.log('videoBlob', videoBlob);
          const file = new File([videoBlob], 'avatar.webm', { type: 'video/webm', lastModified: new Date().getTime() });
          const container = new DataTransfer();
          container.items.add(file);
          // todonext - define fileinputelemment
          avatarFile.current.files = container.files;
        }}
      />
    </div>
  );
}
