import React, { useRef } from 'react';

/*
 * Used to preview the prompt display.
 * Requires glass client js to be loaded.
 */
const PreviewPrompt = (props) => {
  const button = useRef(null);

  const openPrompt = () => {
    Glass.openPrompt(props.operatorName, props.siteName, props.avatarUrl, message(), props.calendarUrl);
  };

  const message = () => {
    let m = null;
    if (props.messageElement) {
      m = document.getElementById(props.messageElement).value;
    } else {
      m = props.message;
    }
    return m;
  };

  return (
    <a
      ref={button}
      onClick={openPrompt}
      className="mr-3 inline-block cursor-pointer rounded-lg bg-gray-100 py-2 px-4 font-medium"
    >
      Preview Call Greeting
    </a>
  );
};

export default PreviewPrompt;
