import React, { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import formatterFn from './timeAgoFormatterFn';

/*
 * Renders the length of the most recent session visit.
 */
export default function SessionStackVisitStarted(props) {
  const [session, setSession] = useState();

  const updateSession = (event) => {
    //  console.debug("SessionStackVisitStarted updateSession", event.detail)
    setSession(event.detail);
  };

  const addSessionActivityEventListeners = () => {
    window.addEventListener('sessionstack:embed:onload', updateSession);
  };

  /**
   * On mount listen for SessionStack session activity.
   */
  useEffect(addSessionActivityEventListeners, []);

  if (!session) {
    return null;
  }
  if (session.isLive) {
    return null;
  }

  return (
    <dd className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
      Visit Started
      <span className="ml-2 font-bold text-black">
        <TimeAgo date={session.client_start} formatter={formatterFn} /> ago
      </span>
    </dd>
  );
}
