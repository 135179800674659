import React from 'react';

export default function SessionStackPlayerEmbed(props) {
  const playerUrl = () => {
    return `https://app.sessionstack.com/player/sessions/${props.session.id}?${requestParams()}`;
  };

  const requestParams = () => {
    // play_live=true&replay_token=${props.replayToken}&ui_mode=simple
    const params = [`replay_token=${props.replayToken}`, 'ui_mode=raw'];
    if (props.session.isLive) {
      params.push('play_live=true');
    }
    return params.join('&');
  };

  const personId = () => {
    return props.session.userIdentity.identifier;
  };

  //  console.debug("SessionStackPlayerEmbed rendering session=", props.session)

  const load = (event) => {
    console.debug('SessionStackPlayerEmbed iframe onload=', props.session);
    window.dispatchEvent(new CustomEvent('sessionstack:embed:onload', { detail: props.session }));
  };

  return (
    <iframe
      src={`${playerUrl()}`}
      className="w-full flex-1"
      sandbox="allow-same-origin allow-scripts"
      onLoad={load}
    ></iframe>
  );
}
