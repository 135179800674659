import React, { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import formatterFn from './timeAgoFormatterFn';
/*
 * Renders the length of the most recent session visit.
 */
export default function SessionStackVisitLength(props) {
  const [session, setSession] = useState();

  const updateSession = (event) => {
    //  console.debug("SessionStackVisitLength updateSession", event.detail)
    setSession(event.detail);
  };

  const addSessionActivityEventListeners = () => {
    window.addEventListener('sessionstack:embed:onload', updateSession);
  };

  /**
   * On mount listen for SessionStack session activity.
   */
  useEffect(addSessionActivityEventListeners, []);

  // There is a bug I can't figure out ... all of the inputs look correct: when a person changes offline/online,
  // the date will comparision will go from the future (ie - 2 min from now).
  const nowFn = () => {
    let res = null;
    if (session.isLive) {
      res = new Date();
    } else {
      res = new Date(session.last_active);
    }
    return res;
  };

  if (!session) {
    console.info('SessionStackVisitLength rendering. No session. Not rendering.');
    return null;
  }

  // On new sessions, I've seen client_start > nowFn(), which renders "x minutes from now".
  // To better handle this, checks if client_start is in the future and if so, just uses now.

  return (
    <dd className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
      Visit Length
      <span className="ml-2 font-bold text-black">
        <TimeAgo
          date={session.client_start > new Date() ? new Date() : session.client_start}
          now={nowFn}
          formatter={formatterFn}
        />
      </span>
    </dd>
  );
}
