/**
 * Removes child elements after a given delay (seconds).
 * Based on https://stackoverflow.com/a/64365185.
 */
import React, { useEffect, useState } from 'react';

const Expire = (props) => {
  const [timer, setTimer] = useState();

  const resetTimer = () => {
    if (timer) {
      console.debug('reset timer for id=', props.id);
      clearTimeout(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    // reset the timer if props.delay changes.
    resetTimer();
    // don't set a new timer if the delay is null
    if (props.delay == null) {
      return;
    }
    // set a timer to call `props.onExpire`
    const newTimer = setTimeout(() => {
      props.onExpire(props.id);
    }, props.delay * 1000);
    setTimer(newTimer);
    console.debug('setting timer for id=', props.id, 'delay=', props.delay);
    // cleanup
    return () => resetTimer();
  }, [props.delay]);

  return <div className="expire-component">{props.children}</div>;
};

export default Expire;
